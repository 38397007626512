import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "CT SERIOUS INJURY NEWS",
    heroH1: "CT SERIOUS INJURY NEWS",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Hartford teenager dies from crash after police chase</h2>
            <p className="mb-8">
            Xavier Myers age 18 was pronounced dead Monday morning following a crash resulting from a police chase from Hartford to Bloomfield.  Police believed there was a gun in the car and attempted to stop the car driven by Myers.  Myers failed to stop which initiated the chase.  A sixteen year old passenger with Myers suffered …
            </p>
            <p className="mb-8"><Link to="/hartford-teenager-dies-from-crash-after-police-chase/">Read More...</Link></p>

            <h2>Norwich CT Motorcycle Rider Dies After Crash</h2>
            <p className="mb-8">
            Andre J. Ross of Norwich was fatally injured in an accident at 2 A.M. Saturday morning.  No other vehicles were involved.  If anyone has information about the accident please call Norwich Officer Andre Rosedal at the Norwich Police Department 860-886-5561. If you or a loved one has been injured or died in an accident in …
            </p>
            <p className="mb-8"><Link to="/norwich-ct-motorcycle-rider-dies-after-crash/">Read More...</Link></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
